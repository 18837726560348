<template>
  <div class="lcf-reset-pwd">
    <NavBar
      title="修改登录密码"
      left-arrow
      fixed
      @click-left="$router.replace({name: 'Personal'})"
      placeholder
    ></NavBar>
    <Form @submit="onSubmit" validate-trigger="onSubmit">
      <Field
        v-model="originalPwd"
        type="password"
        name="old_password"
        label="原始密码"
        placeholder="请输入原始的登录密码"
        :rules="originalPwdRules"
      />
      <Field
        v-model="newPwd"
        type="password"
        name="new_password"
        label="设置新密码"
        placeholder="请输入新的登录密码（8~16位）"
        :rules="newPwdRules"
      />
      <Field
        v-model="newPwdRepeat"
        type="password"
        name="new_password"
        label="确认新密码"
        placeholder="请再输入一次新的登录密码"
        :rules="newPwdRepeatRules"
      />
      <div class="lcf-reset-pwd-btn">
        <Button
          round
          block
          :loading="loading"
          type="primary"
          native-type="submit"
          >确认修改</Button
        >
      </div>
    </Form>
  </div>
</template>

<script>
import { NavBar, Form, Field, Button, Notify, Dialog } from 'vant'
import { mapActions } from 'vuex'

const pwdValidator = (val) => val.length > 7 && val.length < 17

export default {
  components: {
    NavBar,
    Form,
    Field,
    Button,
  },
  data() {
    return {
      loading: false,

      originalPwd: '',
      newPwd: '',
      newPwdRepeat: '',

      originalPwdRules: [
        { required: true, message: '密码不能为空' },
        {
          validator: this.validateAsync,
          message: '原始密码不正确，请重新输入后提交',
        },
      ],
      newPwdRules: [
        { required: true, message: '密码不能为空' },
        {
          validator: pwdValidator,
          message: '登录密码由8-16位的数字、字母或字符组成',
        },
      ],
      newPwdRepeatRules: [
        { required: true, message: '密码不能为空' },
        {
          validator: pwdValidator,
          message: '登录密码由8-16位的数字、字母或字符组成',
        },
      ],
    }
  },
  methods: {
    ...mapActions(['requestResetPwd', 'clearLoginStatus']),
    async onSubmit(values) {
      this.loading = true
      let error, res
      ;[error, res] = await to(this.requestResetPwd(values))

      if (error) {
        Notify({
          type: 'warning',
          message: error.message || error,
        })
        // onSubmit作为原始密码异步校验器 这里返回true表示失败与输入无关
        this.loading = false
        return true
      }

      if (res != null && Number.isNaN(res)) {
        Notify({
          type: 'warning',
          message: res || '修改密码失败',
        })
        // onSubmit作为原始密码异步校验器 这里返回true表示失败与输入无关
        this.loading = false
        return true
      }

      if (res === -1) {
        // success 重新登录
        this.loading = false
        Dialog.alert({
          title: '提示',
          message: '密码修改成功!',
          confirmButtonText: '重新登录',
        }).then(() => {
          this.clearLoginStatus()
          this.$router.push({ name: 'Signin' })
        })
      } else if (res === 112) {
        // 原始密码错误
        this.loading = false
        return false
      } else {
        // ?其他情况归类于原始密码错误?
        this.loading = false
        return false
      }
    },
    async validateAsync() {
      return this.onSubmit({
        old_password: this.originalPwd,
        new_password: this.newPwd,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.lcf-reset-pwd {
  background: $lcf-color-bg-gray-light;
  height: 100%;
  .lcf-reset-pwd-btn {
    margin: 48px 24px 0;
  }
}
</style>
